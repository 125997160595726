export const SET_ACCOUNT = "SET_ACCOUNT";

export const setAccount = (account: string) => {
    return async (dispatch: Function) => {
        dispatch({
            type: SET_ACCOUNT,
            payload: {
                account
            }
        })
    }
}