import { Connectors } from "use-wallet";
import { Chain } from "../types/Chain";

export const networkId = parseInt(process.env.REACT_APP_CHAIN_ID!.toString());

const _getProvider = () => {
  if (networkId === Chain.ethereumMainnet) {
    return process.env.REACT_APP_ETHEREUM_MAINNET_WEB3_PROVIDER;
  }
  if (networkId === Chain.ethereumRopsten) {
    return process.env.REACT_APP_ROPSTEN_WEB3_PROVIDER;
  }
  if (networkId === Chain.ethereumRinkeby) {
    return process.env.REACT_APP_ETHEREUM_RINKEBY_WEB3_PROVIDER;
  }
  if (networkId === Chain.binanceMainnet) {
    return process.env.REACT_APP_BINANCE_MAINNET_WEB3_PROVIDER;
  }
  if (networkId === Chain.binanceTestnet) {
    return process.env.REACT_APP_BINANCE_TESTNET_WEB3_PROVIDER;
  }
}

const _getEtherApi = () => {
  if (networkId === Chain.ethereumMainnet) {
    return process.env.REACT_APP_ETHEREUM_MAINNET_ETHER_API;
  }
  if (networkId === Chain.ethereumRopsten) {
    return process.env.REACT_APP_ETHEREUM_ROPSTEN_ETHER_API;
  }
  if (networkId === Chain.ethereumRinkeby) {
    return process.env.REACT_APP_ETHEREUM_RINKEBY_ETHER_API;
  }
  if (networkId === Chain.binanceMainnet) {
    return process.env.REACT_APP_BINANCE_MAINNET_ETHER_API;
  }
  if (networkId === Chain.binanceTestnet) {
    return process.env.REACT_APP_BINANCE_TESTNET_ETHER_API;
  }
}

const _getImmutableLink = () => {
  if (networkId === Chain.ethereumMainnet) {
    return process.env.REACT_APP_LINK_IMMUTABLEX!.toString();
  }  if (networkId === Chain.ethereumRopsten) {
    return undefined;
  }
}

const _getNetworkId = () => {
  if (networkId === Chain.ethereumMainnet) {
    return '0x1';
  }
  if (networkId === Chain.ethereumKovan) {
    return '0x2'
  }
  if (networkId === Chain.ethereumRopsten) {
    return '0x3'
  }
  if (networkId === Chain.ethereumRinkeby) {
    return '0x4'
  }
  if (networkId === Chain.binanceMainnet) {
    return '0x38'
  }
  if (networkId === Chain.binanceTestnet) {
    return '0x61'
  }
}

export const _getOpenSeaLink = () => {
  return networkId === Chain.ethereumMainnet ? process.env.REACT_APP_OPENSEA : process.env.REACT_APP_TESTNET_OPENSEA;
}

export function setWalletConnected(value: keyof Connectors | null) {
  localStorage.setItem('__WALLET_CONNECTED', JSON.stringify(value));
}

export function isWalletConnected(): keyof Connectors | null {
  const val = localStorage.getItem('__WALLET_CONNECTED');
  return val ? JSON.parse(val) : null;
}

export const web3Provider: any = _getProvider();
export const etherAPI: any = _getEtherApi();
export const openseaLink: any = _getOpenSeaLink();
export const immutableLink: any = _getImmutableLink();
export const id: any  =_getNetworkId();
export const baseURI: string = process.env.REACT_APP_API_BASEURI!.toString()

export const config = {
  networkId,
  web3Provider,
  etherAPI,
  openseaLink,
  setWalletConnected,
  isWalletConnected
};


