import { SET_ACCOUNT } from "../actions/Global";

const initialState: any = {
    account: '',
};

export default function globalReducer(state = initialState, action: any) {
    let changes;
    switch (action.type) {
        case SET_ACCOUNT:
            changes = {
                ...state,
                ...action.payload
            };
            return changes;
        default:
            return state;
    }
}