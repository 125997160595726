import React from 'react'
import ReactDOM from 'react-dom'
import Routing from './router/Router'
import reportWebVitals from './reportWebVitals'
import './theme/global.scss'
import History from './helpers/History'
import thunk from 'redux-thunk';
import globalReducer from './reducers/Global'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import { Router } from 'react-router-dom'
import { UseWalletProvider } from 'use-wallet'
import { config } from './utils/config'
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider, intlReducer } from 'react-intl-redux'
import { composeWithDevTools } from 'redux-devtools-extension' // DISABLE THIS IN PRODUCTION MODE


// #endregion
// #region Create the store, The thunk middleware is used to dispatch fetch calls.
const middleware = [thunk];

const allReducers = combineReducers({
  intl: intlReducer,
  globalReducer: globalReducer
});

const store = createStore(allReducers, composeWithDevTools(applyMiddleware(...middleware)))

ReactDOM.render(
  <Provider store={store}>
    <UseWalletProvider chainId={config.networkId}>
      <Router history={History}>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Routing />
      </Router>
    </UseWalletProvider>
  </Provider>,
  document.getElementById('outer-wrapper'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
