import * as fal from "@fortawesome/pro-light-svg-icons";
import * as fab from "@fortawesome/free-brands-svg-icons";

/**
* @returns Icons from fontawesome
*/
export const icons = {
    times: fal.faTimes,
    twitter: fab.faTwitter,
    discord: fab.faDiscord,
    chevronLeft: fal.faChevronLeft,
    chevronRight: fal.faChevronRight,
    share: fal.faExternalLinkAlt,
    checkmark: fal.faCheck,
    exclamationTriangle: fal.faExclamationTriangle,
};
