import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Button/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../helpers/icons';
import { DialogActions } from '@material-ui/core';

interface Props {
	open: boolean
	title: string,
	subtitle: string,
	isDone: boolean,
	isError: boolean
}

function Loader(props: Props) {

	useEffect(() => { }, [props.isDone])

	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					return;
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className='loader-dialog'>
			<DialogContent>
				<div className="alert-dialog-loading">
					<div className="alert-dialog-loading-icon">
						{!props.isDone && !props.isError && <CircularProgress color="secondary" />}
						{!props.isDone && props.isError && <div className="alert-icon"><i className="fa-light fa-circle-exclamation"></i></div>}
						{props.isDone && <div className='dialog-icon-success'><FontAwesomeIcon icon={icons.checkmark} /></div>}
					</div>
					<div className="alert-dialog-loading-title">
						{props.title}<span>{!props.isDone ? '...' : ''}</span>
					</div>
					<div className='alert-dialog-loading-subtitle'>
						{props.subtitle}
					</div>
				</div>
			</DialogContent>
			{props.isError &&
				<DialogActions className='error-dialog-actions'>
					<Button className='button mint-btn' onClick={() => window.location.reload()}>Retry</Button>
				</DialogActions>
			}
		</Dialog>
	)
}

export default Loader