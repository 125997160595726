/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logolight from '../../assets/images/launchchads-logo.png';
import logodark from '../../assets/images/launchchads-logo.png';
// import opensea from '../../assets/images/open-sea-icon.svg'
// import openseaDark from '../../assets/images/open-sea-icon-dark.svg'
import ConnectButton from '../ConnectButton/ConnectButton';

interface Props {
  onClickCollection: Function
}

function Menu(props: Props) {
  return (
    <>
      <div id="navbar">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="nav-toggle nav-trigger">
              <div className="nav-icon">
                <span></span>
              </div>
            </button>
            <a className="logo-main" href="/">
              <span className="logo-main-light">
                <img src={logolight} alt="Launch Chads" />
              </span>
              <span className="logo-main-dark">
                <img src={logodark} alt="Launch Chads" />
              </span>
            </a>
            <nav id="nav-right-mobile">
              <ul className="nav-social">
                <li>
                  <a href="https://discord.com/invite/fmfh" target="_blank" title="Discord" rel="noreferrer">
                    <i className="fab fa-discord"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/FunkyMonkeyFH" title="Twitter" target="_blank" rel="noreferrer" >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </nav>
            <div className="nav-wrap">
              <nav id="nav-right">
                <ul className="nav-social">
                  <li>
                    <a href="https://discord.com/invite/fmfh" target="_blank" title="Discord" rel="noreferrer">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/FunkyMonkeyFH" target="_blank" title="Twitter" rel="noreferrer">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  {/* <li className="nav-social-image">
                    <a className='opensea-light' href="https://opensea.io/collection/funky-monkey-frat-house" target="_blank" title="Opensea" rel="noreferrer">
                      <img src={opensea} alt="Opensea" />
                    </a>
                    <a className='opensea-dark' href="https://opensea.io/collection/funky-monkey-frat-house" target="_blank" title="Opensea" rel="noreferrer">
                      <img src={openseaDark} alt="Opensea" />
                    </a>
                  </li> */}
                </ul>
                <div className="nav-connect">
                  <ConnectButton className='button l-btn' onClickCollection={props.onClickCollection} enableTextDialog showCollectionButton />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
