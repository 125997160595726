import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from '../../helpers/icons'
import { baseURI } from '../../utils/config'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PuffLoader from 'react-spinners/PuffLoader'
import Card from '../Card/Card'
import Button from '../Button/Button'

interface Props {
  open: boolean
  nfts: Array<any>
  isViewingCollection: boolean
  canViewCollection: boolean
  isLoading: boolean
  onClose: Function
}

let swiper: any;

function NFTShowcase(props: Props) {
  const [contractAddress, setContractAddress]: any = useState('');

  /**
  * @dev Gets the amount available for the user.
  */
  const getContractAddress = async () => {
    try {
      const baseUrl = `${baseURI}/contractAddress`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) { // If the call receives a success set the max mint to the useState, there is no fallback, should there be a fallback?
        setContractAddress(json.data);
      }
    } catch (e) { // If the call fails set the loader to true.
      console.error('getContractAddress Catch', e)
    }
  }

  useEffect(() => {
    getContractAddress();
  }, [])

  return (
    <Dialog
      id="showcase-dialog"
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {props.isViewingCollection
          ? 'Your collection'
          : props.nfts.length <= 1
            ? `Congratulations, you minted ${props.nfts.length} Launch Chad!`
            : `Congratulations, you minted ${props.nfts.length} Launch Chads!`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            !props.isLoading && props.nfts.length > 0
              ? 'The first 1000 brave Launch Chads are headed to the moon, and beyond.'
              : !props.isLoading && props.nfts.length <= 0 ? "You haven't added any Launch Chads to your collection yet!"
                : "Collecting your Launch Chads..."
          }
        </DialogContentText>
        <div className={`slider-wrap ${props.isLoading ? 'is-loading' : ''}`}>
          {props.nfts.length > 1 && (
            <div className="previous" onClick={() => swiper.slidePrev()}>
              <FontAwesomeIcon icon={icons.chevronLeft} />
            </div>
          )}
          {props.isLoading ? (
            <PuffLoader />
          ) : (
            <>
              {props.nfts.length > 0 && (
                <Swiper
                  onSwiper={(initSwiper: any) => swiper = initSwiper}
                  slidesPerView={1}>
                  {props.nfts.length > 0 &&
                    props.nfts.map((nft: any, key: number) => {
                      return (
                        <React.Fragment key={Math.random() + key * key}>
                          <SwiperSlide key={Math.random() + key * key}>
                            <Card contractAddress={contractAddress} nft={nft} />
                          </SwiperSlide>
                        </React.Fragment>
                      )
                    })}
                </Swiper>
              )}
              {props.nfts.length <= 0 && (
                <Swiper
                  onSwiper={(initSwiper: any) => swiper = initSwiper}
                  slidesPerView={1}>
                  <SwiperSlide>
                    <Card contractAddress={contractAddress} nft={null} />
                  </SwiperSlide>
                  {(!props.canViewCollection) && (
                    <SwiperSlide>
                      <Card contractAddress={contractAddress} nft={null} />
                    </SwiperSlide>
                  )}
                </Swiper>
              )}
            </>
          )}
          {props.nfts.length > 1 && (
            <div className="next" onClick={() => swiper.slideNext()}>
              <FontAwesomeIcon icon={icons.chevronRight} />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className="button btn-main"
          onClick={() => {
            props.onClose();
          }}>
          <span>Close</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NFTShowcase
