import { useCallback, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet';
import { id, immutableLink, isWalletConnected, setWalletConnected } from '../../utils/config';
import { showToast, truncateFromCenter } from '../../helpers/sharedFunctions';
import { Link } from '@imtbl/imx-sdk';
import { setAccount } from '../../actions/Global';
import { useDispatch } from 'react-redux';

import Button from '../Button/Button';
import TextDialog from '../TextDialog/TextDialog';
import useWalletImmutable from '../../hooks/useWalletImmutable';

interface Props {
    enableTextDialog: boolean,
    onClickCollection?: Function,
    disabled?: boolean,
    className?: string,
    showCollectionButton: boolean
    id?: string
}

function ConnectButton(props: Props) {
    const wallet = useWallet();
    const { account } = useWallet();
    const immutableAccount = useWalletImmutable();
    const [textDialogState, setTextDialogState] = useState(false);
    const [btnText, setBtnText] = useState('Loading...')
    const [hasLoaded, setHasLoaded] = useState(false)
    const connectedId = isWalletConnected();
    const dispatch = useDispatch();

    /**
     * @dev Connect wallet to metamask
     */
    const connectMetamask = useCallback(async () => {
        if (wallet.status !== 'connected') {
            // is web3 undefined (not installed) show messages
            if (typeof window.ethereum == 'undefined') {
                setTextDialogState(true)
            } else {
                if (wallet.status !== 'connected') {
                    await wallet.connect('injected');
                }
            }
        }
    }, [wallet])

    /**
     * @dev Connect wallet to metamask
     */
    const connectIMX = async () => {
        try {
            const link = new Link(immutableLink);
            const { address } = await link.setup({});
            if (account && address && link) {
                if (address.toLowerCase() !== account!.toString().toLowerCase()) {
                    showToast('Not connected to the same metamask wallet', true);
                } else {
                    setBtnText(truncateFromCenter(address));
                    dispatch(setAccount(address));
                }
            }
        } catch (e) {
            showToast('Immutable window has been closed', true);
            console.error('CATCH connectIMX', e);
        }
    }

    /**
     * @dev Checking if you connected before if yes reconnect.
     */
    const checkConnection = () => {
        if (wallet.status !== 'connected' && connectedId != null) {
            wallet.connect(connectedId)
        }
    }

    const setOrRemoveConnection = useCallback(() => {
        if (wallet && wallet.account) setWalletConnected('injected')
        else localStorage.removeItem('__WALLET_CONNECTED')
    }, [wallet])

    /**
    * @dev Based on the connected chain check if you need to show the wallet address or the wrong network txt
    */
    const getConnectButtonTxt = useCallback(async () => {
        try {
            if (window.ethereum && window.ethereum !== undefined) {
                setTimeout(() => setHasLoaded(true), 300);
                if (hasLoaded) {

                    window.ethereum.on('chainChanged', (changedChainID: any) => {
                        if (changedChainID === id) return window.location.reload()
                        if (changedChainID !== id) return setBtnText('Wrong network')
                        else return setBtnText('Connect Metamask')
                    })
                    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
                    if (chainId !== id) return setBtnText('Wrong network');
                    if (chainId === id && wallet && wallet.account && !immutableAccount && wallet.account.toLowerCase() !== immutableAccount.toLowerCase()) return setBtnText('Connect IMX')
                    if (chainId === id && wallet && wallet.account && immutableAccount && wallet.account.toLowerCase() === immutableAccount.toLowerCase()) return setBtnText(truncateFromCenter(immutableAccount));
                    if (chainId === id && wallet && wallet.account && immutableAccount && wallet.account.toLowerCase() !== immutableAccount.toLowerCase()) {
                        showToast('Switched wallets, Reconnect IMX', true);
                        return setBtnText('Connect IMX');
                    }
                    else return setBtnText('Connect Metamask');
                }
            } else {
                return setBtnText('NO METAMASK FOUND');    
            }
        } catch {
            return setBtnText('NO METAMASK FOUND');
        }
    }, [wallet, hasLoaded, immutableAccount])

    const onCollectionClick = () => {
        if (props.onClickCollection) {
            props.onClickCollection();
            const body = document.body;
            if (body && body.classList.contains('menu-open')) {
                body.classList.remove('menu-open');
            }
        }
    }

    useEffect(() => {
        checkConnection()
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet])

    useEffect(() => {
        setOrRemoveConnection()
    }, [setOrRemoveConnection, wallet])

    useEffect(() => {
        getConnectButtonTxt()
    }, [getConnectButtonTxt, wallet])

    return (
        <>
            <Button
                id={props.id}
                disabled={props.disabled}
                className={`${props.className ? props.className : 'button btn-main'}`}
                onClick={async () => !account ? await connectMetamask() : await connectIMX()}>
                {btnText}
            </Button>
            {props.showCollectionButton &&
                <Button
                    disabled={props.disabled}
                    className={`${props.className ? props.className : 'button btn-main'}`}
                    onClick={async () => onCollectionClick()}>
                    View your collection
                </Button>
            }
            {/* Dialog */}
            {props.enableTextDialog && <TextDialog open={textDialogState} onClose={() => setTextDialogState(false)} />}
        </>
    )
}

export default ConnectButton;