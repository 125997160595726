import Menu from '../../components/Menu/Menu'
import bannerchad1 from '../../assets/images/launchchads-sample-red-left.png';
import bannerchad2 from '../../assets/images/launchchads-sample-orange.png';
import bannerlogo1 from '../../assets/images/immutablex-logo.png';
import bannerlogo2 from '../../assets/images/fhmk-logo-light.svg';
import bannerlogo3 from '../../assets/images/etherium-logo-full-white.svg';
import iconEtheriumlight from '../../assets/images/ethereum-icon-light.svg'
import BigNumber from 'bignumber.js';
import Button from '../../components/Button/Button';
import useWalletImmutable from '../../hooks/useWalletImmutable';
import NFTShowcase from '../../components/NFTShowcase/NFTShowcase';
import Loader from '../../components/Loader/Loader';
import ConnectButton from '../../components/ConnectButton/ConnectButton';
import logolight from '../../assets/images/launchchads-logo.png';
// import opensea from '../../assets/images/open-sea-icon.svg'

import { useCallback, useEffect, useState } from 'react';
import { sendTransaction } from '../../utils/web3';
import { useWallet } from 'use-wallet';
import { showToast } from '../../helpers/sharedFunctions';
import { MintStatus } from '../../types/MintStatus';
import { Inventory } from '../../types/Inventory';
import { baseURI } from '../../utils/config';

let interval: any | NodeJS.Timeout;

function Home() {
  const { ethereum }: { ethereum: any } = useWallet();
  const { account } = useWallet();
  const immutableAccount = useWalletImmutable();
  const [amount, setAmount] = useState(1);

  const [toAddress, setToAddress] = useState('');
  const [mintPrice, setMintPrice] = useState(0);
  const [maxMint, setMaxMint] = useState(0);
  const [totalSupplyLeft, setTotalSupplyLeft] = useState(0);
  const [canMint, setCanMint] = useState(true);
  const [privateSaleOpen, setPrivateSaleOpen] = useState(false);
  const [publicSaleOpen, setPublicSaleOpen] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [isError, setIsError] = useState(false);

  const [canMintHasLoaded, setCanMintHasLoaded] = useState(false);
  const [mintPriceHasLoaded, setMintPriceHasLoaded] = useState(false);
  const [maxMintHasLoaded, setMaxMintHasLoaded] = useState(false);
  const [toAddressHasLoaded, setToAddressHasLoaded] = useState(false);
  const [supplyHasLoaded, setSupplyHasLoaded] = useState(false);

  const [isCreatingMintTicket, setIsCreatingMintTicket] = useState(false);
  const [transactionToBlockchainIsLoading, setTransactionToBlockchainIsLoading] = useState(false);
  const [isMintingLoading, setIsMintingLoading] = useState(false);

  const [loadingStates, setLoadingStates]: any = useState({ title: '', subtitle: '' });
  const [isViewingCollection, setIsViewingCollection]: any = useState(true);
  const [showcaseNFTs, setShowcaseNFTs]: any = useState([]);
  const [showcaseOpen, setShowcaseOpen] = useState(false);
  const [showcaseIsLoadingData, setShowcaseIsLoadingData] = useState(false);
  const [mintedTokens, setMintedTokens]: any = useState([]);

  const [btnValues]: any = useState([3, 5, 10, 15, 20])

  /**
  * @dev Gets the mint price in the API
  */
  const getMintPrice = async () => {
    try {
      // Requesting the mint price from the api
      const baseUrl = `${baseURI}/mintprice`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) {
        setMintPrice(new BigNumber(json.data).div(1e18).toNumber());
        setMintPriceHasLoaded(true);
      } else {
        setIsError(true);
        setLoadingStates({ title: 'Failed to fetch available mints', subtitle: json.message });
      }
    } catch (e) { // If the call fails set the fallback mint price
      console.error('getMintPrice Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch mint price', subtitle: 'Something went wrong, try again.' });
    }
  }

  /**
  * @dev Gets the max mint in the API
  */
  const getMaxMint = async () => {
    try {
      // Requesting the max mint from the api
      const baseUrl = `${baseURI}/maxmint`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) {
        setMaxMint(json.data);
        setMaxMintHasLoaded(true);
      } else {
        setIsError(true);
        setLoadingStates({ title: 'Failed to fetch available mints', subtitle: json.message });
      }
    } catch (e) { // If the call fails set the fallback max mint
      console.error('getMaxMint Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch max mint per transaction', subtitle: 'Something went wrong, try again.' });
    }
  }

  /**
  * @dev Gets the address where the funds should go to
  */
  const getPaymentFundsAddress = async () => {
    try {
      // Requesting the payment funds address from the api
      const baseUrl = `${baseURI}/toaddress`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) {
        setToAddress(json.data);
        setToAddressHasLoaded(true);
      } else {
        setIsError(true);
        setLoadingStates({ title: 'Failed to fetch available mints', subtitle: json.message });
      }
    } catch (e) { // If the call fails set the loader to true.
      console.error('getFundsAddress Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch payment address', subtitle: 'Something went wrong, try again.' });
    }
  }

  /**
  * @dev Gets the address where the funds should go to
  */
  const getTotalSupply = async () => {
    try {
      // Requesting the total minted supply
      const baseUrl = `${baseURI}/supplyLeft`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) {
        setTotalSupplyLeft(json.data);
        setSupplyHasLoaded(true);
      } else {
        setIsError(true);
        setLoadingStates({ title: 'Failed to fetch available mints', subtitle: json.message });
      }
    } catch (e) { // If the call fails set the loader to true.
      console.error('getTotalSupply Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch total supply', subtitle: 'Something went wrong, try again.' });
    }
  }

  /**
  * @dev Gets the address where the funds should go to
  */
  const getSales = useCallback(async () => {
    try {
      // Requesting the total minted supply
      const baseUrl = `${baseURI}/sales`;
      const res = await fetch(baseUrl, { method: "GET" });
      const json = await res.json();
      if (json && json.success) {
        setPublicSaleOpen(json.data.publicSale === 'true');
        setPrivateSaleOpen(json.data.privateSale === 'true');

        if (json.data.privateSale === 'true' && account && immutableAccount) {
          const baseUrl = `${baseURI}/whitelist/${account}`;
          const res = await fetch(baseUrl, { method: "GET" });
          const json = await res.json();
          if (json && json.success) {
            setIsWhitelisted(json.data.whitelisted);
          }
        }
      } else {
        setIsError(true);
        setLoadingStates({ title: 'Failed to fetch sales', subtitle: json.message });
      }
    } catch (e) { // If the call fails set the loader to true.
      console.error('getSales Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch sales', subtitle: 'Something went wrong, try again.' });
    }
  }, [account, immutableAccount])

  /**
 * @dev Gets the amount available for the user.
 */
  const getCanMint = useCallback(async () => {
    try {
      if (account && immutableAccount) {
        const baseUrl = `${baseURI}/canMint`;
        const res = await fetch(baseUrl, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ address: account }) });
        const json = await res.json();
        if (json && json.success) { // If the call receives a success set the max mint to the useState, there is no fallback, should there be a fallback?
          setCanMint(json.data.canMint);
          setAvailableAmount(json.data.availableAmount);
          setCanMintHasLoaded(true);
        } else {
          setIsError(true);
          setLoadingStates({ title: 'Failed to fetch available mints', subtitle: json.message });
        }
      }
    } catch (e) { // If the call fails set the loader to true.
      console.error('getCanMint Catch', e)
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch available mints', subtitle: 'Something went wrong, try again.' });
    }
  }, [account, immutableAccount])

  /**
  * @dev returns the mint price
  */
  const renderMintPrice = () => {
    // Checks if the amount is higher than the maxMint
    if (amount > maxMint) {
      return new BigNumber(mintPrice).times(maxMint).toNumber()
    }
    // Checks if the amount is not equal to NaN
    if (isNaN(new BigNumber(mintPrice).times(amount).toNumber())) {
      return new BigNumber(mintPrice).times(1).toNumber()
    }
    // Returns the mint price times the amount
    return new BigNumber(mintPrice).times(amount).toNumber()
  }

  /**
  * @dev returns the button based on states
  */
  const renderMintButton = () => {
    if (window.innerWidth <= 768) {
      return (
        <Button
          disabled={disableButton()}
          id="mint-btn"
          style={{ minWidth: 100 }}
          className={`button mint-btn`}>
          <span>UNSUPPORTED DEVICE</span>
        </Button>
      )
    } else if (totalSupplyLeft <= 0) {
      return (
        <Button
          disabled={disableButton()}
          id="mint-btn"
          style={{ minWidth: 100 }}
          className={`button mint-btn`}>
          <span>SOLD OUT</span>
        </Button>
      )
    } else if (!account || !immutableAccount) {
      return (
        <ConnectButton
          id='mint-btn'
          showCollectionButton={false}
          className='button mint-btn'
          onClickCollection={() => { }}
          enableTextDialog />
      )
    } else {
      return (
        <Button
          disabled={disableButton()}
          id="mint-btn"
          style={{ minWidth: 100 }}
          className={`button mint-btn`}
          onClick={() => mint()}>
          <span>{getButtonText()}</span>
        </Button>
      )
    }
  }

  /**
  * @dev disables/enables the mint now button based on the amount etc..
  */
  const disableButton = () => {
    if (maxMintHasLoaded && amount > maxMint) return true;
    if (!account) return true;
    if (!immutableAccount) return true;
    if (isCreatingMintTicket) return true;
    if (transactionToBlockchainIsLoading) return true;
    if (isMintingLoading) return true;
    if (immutableAccount.toLowerCase() !== account.toLowerCase()) return true
    if (totalSupplyLeft <= 0) return true
    if (amount > totalSupplyLeft) return true;
    if (availableAmount <= 0) return true;
    if (amount > availableAmount) return true;
    if (!canMint) return true;
    if (privateSaleOpen && !isWhitelisted) return true;
    if (!privateSaleOpen && !publicSaleOpen) return true;
    return false;
  }

  /**
  * @dev disables/enables the mint now button based on the amount etc.. and shows the error message
  */
  const disabledErrorMessage = () => {
    if (maxMintHasLoaded && amount > maxMint) return `Max mint is ${maxMint}`;
    if (amount > totalSupplyLeft) return `Exceeding mint amount, there is ${totalSupplyLeft} left`;
    if (amount > availableAmount) return `Available amount is ${availableAmount}`;
    return '';
  }

  /**
 * @dev When the mint pops an error, show a toast message.
 * @param errorMessage: Not required.
 */
  const onMintError = (errorMessage?: string) => {
    if (errorMessage && errorMessage !== '') {
      showToast(errorMessage, true);
    } else {
      showToast('Something went wrong, try again later...', true);
    }

    setIsCreatingMintTicket(false);
    setTransactionToBlockchainIsLoading(false);
    setIsCreatingMintTicket(false);
  }

  /**
  * @dev Renders the butto text based on all the loaders
  */
  const getButtonText = () => {
    if (privateSaleOpen && !isWhitelisted) {
      return 'Not whitelisted';
    }
    if (availableAmount <= 0) {
      return 'Reached maximum mint';
    }
    if (canMintHasLoaded && supplyHasLoaded && toAddressHasLoaded && mintPriceHasLoaded && maxMintHasLoaded && disabledErrorMessage().length > 0) {
      return disabledErrorMessage()
    }
    if (isMintingLoading || transactionToBlockchainIsLoading || isCreatingMintTicket) {
      return 'Loading...'
    }

    return 'Mint now';
  }


  /**
  * @dev Renders the butto text based on all the loaders
  */
  const pollMintStatus = async (ids: Array<number>) => {
    try {
      // Fetching the status based on the mint id's
      const baseUrl = `${baseURI}/checkstatus`;
      const res = await fetch(baseUrl, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ ids: ids }) });
      const json = await res.json();
      // If the json length is higher than 0, than we know we got a response back
      if (json && json.length > 0) {
        const status = json[0].status;
        // Checking if the status === 3 (COMPLETED)
        // setLoadingStates({ title: 'Starting mint process', subtitle: 'We are almost there, one moment please...' });
        setLoadingStates({ title: 'Starting mint process', subtitle: 'We are almost there, this process can take longer than usual. If this takes too long you can close the window and check your IMX inventory at a later time.' });
        if (status === MintStatus.COMPLETED) {
          // Clear the interval
          clearInterval(interval);
          // Setting the loader state
          setLoadingStates({ title: 'Finishing mint process', subtitle: 'Updating your inventory' });
          // Parsing the token id's and looping through it to create a call to ipfs for the metadata
          const tokens = JSON.parse(json[0].tokens);
          let count: number = 0;
          let nfts: any = [];
          // Iterating through all the token id's to get the ipfs data to show your new minted tokes
          tokens.forEach(async (tokenId: number) => {
            const baseUrl = `https://ipfs.launchchads.com/ipfs/Qmb6pciuB3Y8R6Ds6YQQSfWgLoBRbwb9VUAUxWw8y7habk/` + tokenId;
            const res = await fetch(baseUrl, { method: "GET" });
            const json = await res.json();
            nfts.push(json);
            count++;
            // If the count is equal or higher than than tokens.length than set all the loaders to false and show the collection with new minted tokens
            if (count >= tokens.length) {
              // Setting minted tokens
              setMintedTokens(nfts);
              setIsViewingCollection(false);
              // Turn off all the loaders
              setIsCreatingMintTicket(false);
              setTransactionToBlockchainIsLoading(false);
              setIsCreatingMintTicket(false);
              setIsMintingLoading(false);
              // Get the new supply
              getTotalSupply();
              // Get the new available amount to mint
              getCanMint();
              // Open the showcase
              setShowcaseOpen(true);
            }
          });
        }
      }
    } catch (e) {
      console.error('pollMintStatus CATCH', e);
      setIsError(true);
      setLoadingStates({ title: 'Failed to fetch mint status', subtitle: 'Something went wrong, Contact the admins' });
    }
  }

  /**
  * @dev Makes a call to the api and creates a `ticket` if the ticket has been made succesfully, create a transaction to pay for the NFT.
  */
  const mint = async () => {
    try {
      // Creating a mint ticket state && setting the loader state
      setIsCreatingMintTicket(true);
      setLoadingStates({ title: 'Registrating mint', subtitle: 'This can take a moment, please wait' });
      // Making a call to create a mint receipt
      const baseUrl = `${baseURI}/mint`;
      const res = await fetch(baseUrl, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ address: account, amount: amount }) });
      const json = await res.json();
      setTimeout(async () => { // Added a timeout of 2 seconds because the response is super fast.
        if (json && json.success) {
          // The transaction id
          const id = [json.id];
          // Removing the create ticket state and starting the transaction to blockchain
          setIsCreatingMintTicket(false);
          setTransactionToBlockchainIsLoading(true);
          setLoadingStates({ title: 'Waiting for payment', subtitle: 'Make sure to confirm your transaction in your wallet' });
          const priceInWei = new BigNumber(renderMintPrice()).times(1e18).toString();
          await sendTransaction(ethereum, account, toAddress, '', priceInWei,
            () => {
              // Turn off all the loaders
              setTransactionToBlockchainIsLoading(false);
              setIsMintingLoading(true);
              setLoadingStates({ title: 'Validating payment', subtitle: 'Waiting for ethereum network to complete the transaction' });
            },
            (tx: any) => {
              // Poll the mint to create a mint on IMX
              interval = setInterval(() => pollMintStatus(id), 3000);
            },
            (err: any) => {
              if (err && err.code === 4001) {
                onMintError('Transaction has been denied')
              } else {
                onMintError(err)
              }
            },
          )
        } else {
          onMintError(json && json.result ? json.result : json && json.message ? json.message : 'Something went wrong, try again later');
        }
      }, 2000)
    } catch (e) {
      setLoadingStates({ title: 'Something went wrong', subtitle: 'There was a problem creating the transaction' });
      setTimeout(() => {
        setIsCreatingMintTicket(false);
        setTransactionToBlockchainIsLoading(false);
        setIsCreatingMintTicket(false);
      }, 3000)
    }
  }

  /**
  * @dev Makes a call to IMX for the inventory
  */
  const getInventory = useCallback(async () => {
    // Checking if the user is connected to metamask and IMX because these cannot be 0 or null inorder to request your inventory
    if (account && immutableAccount) {
      try {
        // Showcase is loading
        setShowcaseIsLoadingData(true);

        const baseUrl = `${baseURI}/inventory`;
        const res = await fetch(baseUrl, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ address: account }) });
        const json = await res.json();

        // Iterate throught transactions and get all the token ids
        let NFTs: Array<number> = [];
        let count: number = 0;
        if (json && json.length > 0) {
          json.forEach((transaction: Inventory) => {
            if (transaction && transaction.tokens) {
              const tokens = JSON.parse(transaction.tokens);
              tokens.forEach(async (tokenId: number) => {
                // Iterate through token ids and get all the metadata from ipfs
                const baseUrl = `https://ipfs.launchchads.com/ipfs/Qmb6pciuB3Y8R6Ds6YQQSfWgLoBRbwb9VUAUxWw8y7habk/` + tokenId;
                const res = await fetch(baseUrl, { method: "GET" });
                const json = await res.json();
                if (json) {
                  NFTs.push(json);
                  count++;
                }
                // If the count is higher or equal to the NFTs array than push it to the useState
                if (count >= NFTs.length) {
                  setShowcaseNFTs(NFTs);
                  // Showcase is not loading anymore
                  setShowcaseIsLoadingData(false);
                }
              })
            } else {
              setShowcaseIsLoadingData(false);
            }
          });
        } else {
          setShowcaseIsLoadingData(false);
        }
      } catch (e) {
        setShowcaseIsLoadingData(false);
      }
    }
  }, [account, immutableAccount])

  /**
  * @dev useEffect (onEnter)
  */
  useEffect(() => {
    getMintPrice();
    getMaxMint();
    getPaymentFundsAddress();
    getInventory();
    getTotalSupply();
    getCanMint();
    getSales();
  }, [account, getInventory, immutableAccount, getCanMint, getSales])

  return (
    <div id="main-wrapper">
      <Menu onClickCollection={() => setShowcaseOpen(true)} />
      <div id="bg-wrapper"></div>
      <div id="content-top-wrapper">
        <div id="banner-wrapper">
          <div id="banner-main">
            <div className="container">
              <div className="banner-main-wrap">
                <div className="banner-main-block">
                  <div className="banner-main-title">
                    <p>{publicSaleOpen ? 'PUBLIC SALE' : privateSaleOpen ? 'PRIVATE SALE' : 'MINTING HAS BEEN PAUSED'}</p>
                    <h1>GOING WHERE NO CHAD <br /> HAS GONE BEFORE</h1>
                  </div>
                  <div className="banner-main-center">
                    <p>
                      The first 1000 brave Launch Chads are headed to the moon, and beyond.
                    </p>
                    <div className="banner-main-center-content">
                      <div className="banner-main-price-block">
                        <div className="banner-main-price-icon">
                          <img src={iconEtheriumlight} alt="Etherium" />
                        </div>
                        <div className="banner-main-price">
                          <div>
                            {renderMintPrice()}
                          </div>
                          <span>ETH</span>
                        </div>
                      </div>
                      <div className="banner-input-wrap">
                        <div className="banner-main-btm">
                          {btnValues.map((value: number) =>
                            <Button key={'button-' + value} className="button chip-btn" style={{ width: window.innerWidth >= 768 ? (100 / btnValues.length - 4.0) + '%' : (100 / btnValues.length - 2.0) + '%' }} onClick={() => setAmount(value)}>
                              <span>{value}</span>
                            </Button>
                          )}
                        </div>
                        <div className="banner-form">
                          <div className="banner-input">
                            <input value={amount} onChange={(e: any) => setAmount(e.target.value)} />
                          </div>
                          {renderMintButton()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-logos">
                <div className="banner-logo-block">
                  <a href="https://www.immutable.com/" target="_blank" title="Immutable X" rel="noreferrer">
                    <img src={bannerlogo1} alt="Immutable X" />
                  </a>
                </div>
                <div className="banner-logo-block">
                  <a href="https://funkymonkeyfrathouse.com/" target="_blank" title="Funky Monkey Frathouse" rel="noreferrer">
                    <img src={bannerlogo2} alt="Funky Monkey Frathouse" />
                  </a>
                </div>
                <div className="banner-logo-block">
                  <a href="https://ethereum.org/en/" target="_blank" title="Ethereum" rel="noreferrer">
                    <img src={bannerlogo3} alt="Ethereum" />
                  </a>
                </div>
              </div>
              <div className="small-text">
                <p>
                  Powered by Immutable X. The first Layer 2 for NFTS on Ethereum. Experience zero gas fees, instant trades, and carbon neutral NFTs for marketplaces, games, and applications without compromise.
                </p>
              </div>
            </div>
            <div className="banner-img-small bm-img-left">
              <img src={bannerchad1} alt="Launch Chads" />
            </div>
            <div className="banner-img-small bm-img-right">
              <img src={bannerchad2} alt="Launch Chads" />
            </div>
          </div>
        </div>
      </div>
      <div id="content-faq" className="content-banner-wrap content-banner-bottom">
        <div className="container">
          <div className="content-banner content-banner-center">
            <div className="banner-blk-txt">
              <div className="content-banner-title">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="content-banner">
            <div id="faq" className="faq-main">
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq1">
                    What are the Launch Chads?
                  </button>
                </div>
                <div id="faq1" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      Launch Chads are a collection of 1000 NFTs. They are set apart to participate in the moon mission of an ecosystem that Funky Monkey Frat House is building. The goal is to fund Launchpads, and these 1000 Launch Chad NFTs act as membership access to the lion’s share of those benefits. The future is bright.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq2">
                    How do I mint?
                  </button>
                </div>
                <div id="faq2" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      (PLEASE NOTE: Do not try to mint on mobile using your metamask. It will not work.)
                      <br /><br />
                      Simple steps:
                      Go to https://market.x.immutable.com/inventory (this is your wallet viewer for IMX)
                      Make sure you connect your wallet to IMX in the upper-right-hand corner of your page.
                      Click Deposit, and then select ETH from the drop-down menu.
                      Send 0.1000001 Ethereum (that little bit extra will honestly get you an incredible amount of transactions).
                      There is a couple other MetaMask prompts you’ll need to click “continue” “sign” “submit” etc.
                      —After there is ETH in your IMX account—
                      Go to https://launchchads.com/ and connect your MetaMask wallet in the upper-right-hand corner of the website.
                      Select the desired amount you wish to mint
                      Click Mint
                      Go and view your Launch Chad back on https://market.x.immutable.com/inventory
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq3">
                    What chain is this on?
                  </button>
                </div>
                <div id="faq3" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      IMX is an Ethereum Layer 2 solution. It allows for “gas-less” transactions while maintaining security. Truth is the gas is just incredibly fractional that it’s not worth mentioning, but the point of this is it allows for much cheaper minting when it comes to NFTs. So which chain is it? It’s Ethereum, just a layer 2. Your address will be the same but your balances on the marketplace portion of their explorer will just reveal what’s on that layer, as it is separate from your Ethereum Mainnet balances.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq4">
                    How do I connect to MetaMask?
                  </button>
                </div>
                <div id="faq4" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      Fairly self-explanatory, but the upper right-hand corner of the website has a “Connect Wallet” button. Use that to connect your MetaMask. Don’t have MetaMask installed? Head to Metamask.io to download and install.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq5">
                    How do I transfer ETH to IMX?
                  </button>
                </div>
                <div id="faq5" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      Go to https://market.x.immutable.com/inventory (this is your wallet viewer for IMX)
                      Make sure you connect your wallet to IMX in the upper-right-hand corner of your page.
                      Click Deposit, and then select ETH from the drop-down menu.
                      Send 0.1000001 Ethereum (that little bit extra will honestly get you an incredible number of transactions).
                      There is a couple other MetaMask prompts you’ll need to click “continue” “sign” “submit” etc.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq6">
                    What is the mint price?
                  </button>
                </div>
                <div id="faq6" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      {new BigNumber(mintPrice).times(1).toNumber() || 0.1} ETH
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq7">
                    What about gas price?
                  </button>
                </div>
                <div id="faq7" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      IMX is an Ethereum layer 2 solution. This basically means that transactions are near-free to execute, including minting functions on contracts. Your gas price will be almost zero. No more gas wars or high fees.
                      Will this benefit Funky Monkey NFT holders?
                      This is a precursor to a collection called Crypto Chads which will also be claimable 1-to-1 for every Funky Monkey Frat House NFT you hold. The initial Launch Chads are going towards funding a separate ecosystem project, but are based on the Crypto Chads collection, which will be available to FMFH holders sometime in Q1 (estimated, not confirmed).
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq8">
                    Where do I view my Launch Chads?
                  </button>
                </div>
                <div id="faq8" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      IMX Marketplace will be the place to view your Launch Chad NFT or in the upper right-hand corner of the website has a “View your collection” button. Use that to view your minted collection.
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-blk">
                <div className="faq-header">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#faq9">
                    Can I bring my IMX minted Launch Chad back to Ethereum Mainnet?
                  </button>
                </div>
                <div id="faq9" className="collapse" data-parent="#faq">
                  <div className="faq-body">
                    <p>
                      Yes. IMX does have to “Prepare” the transaction, which takes about 24 hours, then you can withdraw to your ETH main-net account. There isn’t a ton of reason to do this unless you’d like to list on OpenSea, but you can buy/sell your NFT directly on IMX’s marketplace if you don’t want to go through all that trouble and save yourself any further gas fees as well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container">
          <div className="footer-content">
            <div className="footer-logo-main">
              <a className="footer-logo" href="/">
                <img src={logolight} alt="Launch Chads" />
              </a>
            </div>
          </div>
          <div id="footer-bottom">
            <div className="footer-copyright">
              &copy; Copyright <span id="copyright-year">2021</span> Launch Chads
            </div>
            <ul className="footer-social">
              <li>
                <a href="https://discord.com/invite/fmfh" target="_blank" title="Discord" rel="noreferrer">
                  <i className="fab fa-discord"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/FunkyMonkeyFH" target="_blank" title="Twitter" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              {/* <li className="nav-social-image">
                <a className='opensea-light' href="https://opensea.io/collection/funky-monkey-frat-house" target="_blank" title="Opensea" rel="noreferrer">
                  <img src={opensea} alt="Opensea" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <NFTShowcase
        open={showcaseOpen}
        nfts={mintedTokens && mintedTokens.length >= 1 ? mintedTokens : showcaseNFTs}
        isViewingCollection={isViewingCollection}
        canViewCollection={true}
        isLoading={showcaseIsLoadingData}
        onClose={() => {
          setShowcaseOpen(false);
          setMintedTokens([]);
          setIsViewingCollection(true);
          getInventory();
        }}
      />
      <Loader
        open={isMintingLoading || transactionToBlockchainIsLoading || isCreatingMintTicket || isError}
        title={loadingStates.title}
        subtitle={loadingStates.subtitle}
        isError={isError}
        isDone={false}
      />
    </div>
  )
}

export default Home
