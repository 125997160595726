import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ios from '../../assets/images/ios-store-btn.svg'
import android from '../../assets/images/google-play-btn.svg'

interface Props {
    open: boolean,
    onClose: Function,
}

function TextDialog(props: Props) {

    return (
        <Dialog
            className='text-dialog'
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Connection Failed</DialogTitle>
            <DialogContent>
                <p>We couldn't connect to your provider. It's likely that the metamask browser plugin is not installed.</p>
                <p>If you are on a mobile device then install the Metamask app on your mobile and open this URL via the Metamask in app browser.</p>
                <div className='alert-img-container'>
                    <a href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202" target='_blank' rel="noreferrer"><img src={ios} alt="Ios Store" /></a>
                    <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=nl&gl=US" target='_blank' rel="noreferrer"><img src={android} alt="Play Store" /></a>
                </div>
            </DialogContent>
            <DialogActions>
                <button className="button btn-main" onClick={() => props.onClose()}>
                    <span>Close</span>
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default TextDialog;
