import { useState } from 'react';
import placeholder from '../../assets/images/launchchads-nft-placeholder.png';
import { getMarketPlaceURL } from '../../utils/immutable';

interface Props {
    nft: any,
    contractAddress: string
}

function Card(props: Props) {
    const [hasError, setHasError]: any = useState([])

    const openInventoryNFT = () => {
        if (props.nft && props.nft.name) {
            const tokenID = props.nft.name.split('#')[1];
            const url = getMarketPlaceURL() + `inventory/${props.contractAddress}/${tokenID}`;
            window.open(url, '_blank')
        }
    }

    return (
        <div className='mintcard-wrap'>
            <div className={`alert-img ${hasError ? 'placeholder' : ''}`} onClick={() => openInventoryNFT()}>
                {props.nft && props.nft.image
                    ? hasError[props.nft.image]
                        // eslint-disable-next-line jsx-a11y/alt-text
                        ? <div><img src={props.nft.image} onError={e => { e.currentTarget.src = placeholder; setHasError([props.nft.image]); }} /></div>
                        // eslint-disable-next-line jsx-a11y/alt-text
                        : <img src={props.nft.image} onError={e => { e.currentTarget.src = placeholder; setHasError(true) }} />
                    :
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={props.nft && props.nft.image ? props.nft.image : placeholder} onError={e => { e.currentTarget.src = placeholder; setHasError(true) }} />
                }
                {props.nft && props.nft.name &&
                    <p>{props.nft.name}</p>
                }
            </div>
        </div >
    )
}

export default Card