import { Chain } from "../types/Chain";
import { networkId } from "./config";

export const getPublicApiURL = () => {
    if (networkId === Chain.ethereumMainnet) {
        return process.env.REACT_APP_PUBLIC_API_URL!.toString();
    }
    if (networkId === Chain.ethereumRopsten) {
        return process.env.REACT_APP_PUBLIC_API_ROPSTEN_URL!.toString();
    }
    
    return process.env.REACT_APP_PUBLIC_API_ROPSTEN_URL!.toString();
}

export const getMarketPlaceURL = () => {
    if (networkId === Chain.ethereumMainnet) {
        return process.env.REACT_APP_IMMUTABLE_MAINNET_MARKET!.toString();
    }
    if (networkId === Chain.ethereumRopsten) {
        return process.env.REACT_APP_IMMUTABLE_ROPSTEN_MARKET!.toString();
    }
 
    return process.env.REACT_APP_IMMUTABLE_MAINNET_MARKET!.toString();
}