import { toast } from 'react-toastify';

export const truncateFromCenter = (value: string): string => {
    return value.substring(0, 8) + '...' + value.substring(value.length - 3, value.length);
}

export const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const showToast = (txt: string, error: boolean) => {
    return toast(txt, {
        toastId: txt + Math.random + Math.random,
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        progressClassName: error ? 'error' : 'success',
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        className: error ? 'error' : 'success',
        progress: undefined,
    });
}