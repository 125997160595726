import Web3 from 'web3';
import { config } from './config';

const providerUrl: any = config.web3Provider;
const web3 = new Web3(providerUrl);

declare global {
    interface Window {
        ethereum: any;
    }
}

export const sendTransaction = async (provider: any, fromAddress: any, toAddress: any, encodedABI: any, wei = `0x0`, onConfirm?: Function, onSuccess?: Function, onError?: Function) => {
    const web3 = new Web3(window.ethereum || providerUrl || provider);
    if (web3) {
        try {
            const gasPrice = await web3.eth.getGasPrice();
            const tx = {
                from: fromAddress,
                to: toAddress,
                gasPrice: web3.utils.toHex(gasPrice),//`0xAB5D04C00`,
                data: encodedABI,
                value: wei
            };
            return new Promise((resolve, reject) => {
                web3.eth.sendTransaction(tx)
                    .on('transactionHash', function (hash) {
                        if (onConfirm) onConfirm(hash)
                    })
                    .on('receipt', (receipt) => {
                        resolve(receipt);
                        if (onSuccess) onSuccess(receipt)
                    })
                    .on('error', (err) => {
                        console.log('err :>> ', err);
                        reject(err);
                        if (onError) onError(err)
                    });
            });
        } catch (err) {
            console.log('err :>> ', err);
            return null;
        }
    } else {
        return null;
    }
}

export {
    web3,
    providerUrl
}